window.addEventListener('DOMContentLoaded', () => {
  const LAZY_LOAD_SELECTOR = '.js-lazy-load'

  const lazyLoadEls = Array.prototype.slice.call(document.querySelectorAll(LAZY_LOAD_SELECTOR))

  if (lazyLoadEls.length) {
    const config = {
      threshold: 0.5
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0.5) {
          const target = entry.target
          const dataSrcSet = target.getAttribute('data-srcset');
          const picture = target.closest('picture')

          if (picture) {
            const sources = picture.querySelectorAll('source');
            if (sources.length > 0) {
              sources.forEach(source => {
                const sourceDataSrcSet = source.getAttribute('data-srcset')
                if (sourceDataSrcSet) {
                  source.setAttribute('srcset', sourceDataSrcSet)
                }
              })
            }
          }

          if (dataSrcSet) {
            target.setAttribute('srcset' , dataSrcSet)
          }
        }
      });
    },config);

    lazyLoadEls.forEach(lazyLoadEl => {
      observer.observe(lazyLoadEl);
    });
  }
})